import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import { checkIsLoggedIn, clearCookies, fetchCountryData, fetchProvinceData } from "../PageFunctions/commonFunctions";
import OrderCreateEdit from "../../components/OrderCreateEdit";
import { fetchDetailedOrderData } from "../PageFunctions/orderFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const OrderCreateEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {orderId} = useParams();
	const navigate = useNavigate();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();

	const [orderData, setOrderData] = useState(null);
	const [countryData, setCountryData] = useState(null);
	const [provinceData, setProvinceData] = useState(null);

	const [fetchOrderDataCallStatus, setFetchOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchProvinceDataCallStatus, setFetchProvinceDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchCountryDataCallStatus, setFetchCountryDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoOrdersPage = () => navigate("/orders");
	const reloadPage = () => navigate(0);

	const fetchOrderDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedOrderData({
				auth0Token,
				orderId,
				setOrder: setOrderData,
				setStatusInProgress: () => setFetchOrderDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchOrderDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchOrderDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchOrderDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchCountryDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchCountryData({
				auth0Token,
				setCountries: setCountryData,
				setStatusInProgress: () => setFetchCountryDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchCountryDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchCountryDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchCountryDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const fetchProvinceDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchProvinceData({
				auth0Token,
				setProvinces: setProvinceData,
				setStatusInProgress: () => setFetchProvinceDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchProvinceDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchProvinceDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchProvinceDataCallStatus(ApiCallStatus.Error)

			});
		}
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchOrderDataAsync(),
			fetchProvinceDataAsync(),
			fetchCountryDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	const allLoadingStates = [
		fetchOrderDataCallStatus,
		fetchProvinceDataCallStatus,
		fetchCountryDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<OrderCreateEdit
				onBackClick={gotoOrdersPage}
				order={orderData}
				provinces={provinceData}
				countries={countryData}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default OrderCreateEditPage;