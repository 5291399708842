import React from "react";
import { Grid } from "semantic-ui-react";
import ProductCreateEditForm from "../../forms/ProductCreateEditForm";

const ProductCreateEdit = ({
	product,
	onBackClick
}) => {

	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					Product
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{!!product &&
						<ProductCreateEditForm
							product={product}
							goBack={onBackClick}/>
					}
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default ProductCreateEdit;