export const storage_vehicle = "9e63097a-6bd3-4060-b5f3-6e5cb7e73cde";
export const local_storage_token = "1c28ed04-5862-443a-80a8-5e0674a97651";
export const session_storage_token = "bacd1f55-e569-46a7-920b-c8d85474a17f";
export const local_storage_refresh_token = "185b0c5b-b52d-44c3-93d3-9e040643ad3b";
export const storage_user_id = "3182e2af-1983-4cb3-9088-74ed761b5491";
export const storage_user_email = "aa14af4b-7a1a-4cd1-86f6-431ae0edb81a";
export const storage_user_firstName = "46860585-d276-4994-b103-34040c49fa97";
export const storage_user_lastName = "b1339e1f-45a9-4ae1-959c-3e5917680522";
export const storage_user_tecfinity_account_numbers = "873124a9-84a1-434d-b657-5b7253503741";
export const storage_user_tecfinity_account = "03ed6615-918f-42eb-a8e8-ec6f48e7c969";
export const storage_user_tecfinity_account_number = "97b866f9-6fc0-4eed-8a27-3ea94093158e";
export const storage_user_tecfinity_name = "8519b808-8f09-4950-8fb5-c949b6d8f61e";
export const storage_user_tecfinity_category = "a5c5bef5-67f2-4fbf-b0c7-48c3cc49885c";

export const OrderNumberPrefix = "ARG";

export const LoggedInStatus = Object.freeze({
	NotChecked: Symbol(1000),
	Yes: Symbol(1001),
	Refreshed: Symbol(1002),
	No: Symbol(1003)
});

export const PaymentType = {
	AccountCredit: "6fab1f25-5c4b-4681-9aa7-c6f245ba039e",
	PayFast: "b4ef31f0-05e3-49ab-940f-fe42ddaa6e89",
	EFT: "87c316c5-1b31-45e8-a3af-c39f4385436a",
	CreditCardOnCollection: "a9ca4819-da6a-4843-9d95-670c70ae7322"
};

export const PaymentStatus = {
	Complete: "417c2635-24d9-4687-9032-a679dbee6e74",
	Failed: "ebb5979d-5645-4e8a-8e20-33870b91a587",
	Pending: "810b136b-0b48-4756-9196-a3cf5bc65614",
	Voided: "a0502a00-fa8a-42b5-ac3e-f17242c13ca7",
	Overdue: "c8971eae-42de-4bcc-9349-c10477763d95"
};

export const OrderStatus = {
	Open: "83412650-9EF8-4858-B732-8905A7E98D2B",
	Closed: "6E101585-DC6B-48FF-9714-C48B81FECC37",
	Archived: "5870E75B-DB99-4C32-8DB1-797699640A62",
	Cancelled: "877EAC74-1E4F-4659-98DD-4D4F99AA82B1"
};

export const FulfillmentStatus = {
	Complete: "9F196A33-BC2C-4E47-B37D-6C69E5289DA7",
	Unfulfilled: "4FC90E38-0653-4B79-8DE7-557D517CC3E2",
	New: "5AB7F020-C141-4E03-A438-52C7E69999C3",
	Scheduled: "9BD94C55-9392-4073-ADC5-F82FA62CF286",
	OnHold: "BE1034C7-4BB0-4AF6-A3B1-296EA5760D3A",
	AwaitingShipment: "C80D9052-EC08-4D30-96A3-4B8B88E0982A",
	ReadyForCollection: "622F7C7C-E763-4CE3-8AC9-B45B302F1C6D",
	Fulfilled: "B53D6D30-C261-481D-A363-19D4FC8AB627"
};

export const OrderPaymentStatus = {
	Paid: "a618a2b1-9108-46fa-aa05-e05641a01c58",
	PartiallyPaid: "e5bdfa70-4478-4226-8b6e-b3066b1593e6",
	PendingPayment: "c07203c3-c4d3-4d80-8e3c-1d71efee0a3e",
	Unpaid: "7ca57b4f-6728-4084-b1b8-07f8f47aaa5d",
	Refunded: "a870998f-7a85-499c-bdeb-adb2add7a6c6",
	PartiallyRefunded: "8ca91f97-e616-45b3-b05e-7297546d5117"
};

export const OrderFulfillmentStatus = {
	Complete: "A4472632-F647-491B-9A5B-B928CC1D2D44",
	PartiallyUnfulfilled: "896235DA-EAB2-47B2-BA19-C19FD8D2BC90",
	Unfulfilled: "BAC6F9E7-73B2-4051-89E5-31721125A80B",
	Scheduled: "F7CF9795-298A-4746-AEE2-ADD7AE67E0DA",
	OnHold: "07BC08B7-CA8A-4B4E-92AF-DD29FACABAFA",
	New: "40A58F90-472F-4D71-8B86-47395D1E1599",
	AwaitingShipment: "E8BFED83-FC64-407D-8516-78F1365113AC",
	ReadyForCollection: "429960E8-F858-4021-8EF7-B13072C62423",
	Fulfilled: "8F92FDE6-C93F-480E-8ACB-D2C1B5299712"
};

export const PaymentConfirmationMessage = {
	HeadingComplete: "Order completed successfully!",
	HeadingPartialComplete: "Payment completed successfully!",
	HeadingFailed: "Something went wrong!",
	HeadingPending: "Awaiting payment",
	MessageComplete: "Your order is accepted",
	MessagePartialComplete: "Please pay the balance to complete your order",
	MessageFailed: "Payment could not be completed",
	MessagePending: "Your order will be fulfilled once payment is received"
};

export const OrderLogisticsStatus = {
	Ready: "89E24FD2-8D68-4A2A-AADC-2021C2133570",
	Pending: "7337A2A0-73C7-4A2C-86CC-2E29F1A0B754",
	MethodChosen: "090C4D3D-9B63-466B-956D-4C0A3D705F68"
};

export const AccountType = {
	Trade: "trade",
	Retail: "retail",
	Guest: "guest"
};

export const DeliveryMethod = {
	Collection: "pickup",
	Ship: "ship"
};

export const LogisticsMethod = {
	Collection: "4C2D06AB-F3A7-4308-9541-7A9AC7F6D18C",
	Ship: "CBD12DBB-8F07-4042-BF0C-EF2121AAAD88"
};

export const PaymentMethod = {
	AccountCredit: "account_credit",
	PayFast: "payfast",
	EFT: "eft",
	CardOnCollection: "card_on_collection"
};

export const AddressType = {
	Billing: Symbol(1000),
	Shipping: Symbol(1001),
	Collection: Symbol(1002)
};

export const default_ship_via = "155";
export const ProductAvailability = {
	InStock: "In Stock",
	LowStock: "Low Stock",
	ThreeToFiveDays: "3-5 Days",
	No_Stock: "No Stock"
};

export const PayfastResponsePaymentStatus = {
	Complete: "COMPLETE",
	Cancelled: "CANCELLED"
};

export const PageStatus = {
	NotLoaded: Symbol(1000),
	AuthCheckStarted: Symbol(1001),
	AuthChecksFailed: Symbol(1002),
	AuthChecksCompleteDataFetchNotStarted: Symbol(1003),
	DataFetchStarted: Symbol(1004),
	DataFetchComplete: Symbol(1005),
	DataFetchFailed: Symbol(1006)
};


export const ApiCallStatus = Object.freeze({
	NotStarted: Symbol(2000),
	InProgress: Symbol(2001),
	Succeeded: Symbol(2002),
	Failed: Symbol(2003),
	Error: Symbol(2004)
});

export const ApiCallResult = Object.freeze({
	NoResult: Symbol(3000),
	Success: Symbol(3001),
	Failed: Symbol(3002),
	Error: Symbol(3003)
});

export const PriceCategory = {
	Retail: "ret",
	Trade: "tra",
	Wholesale: "ws1",
	Wholesale2: "ws2",
	Wholesale3: "ws3",
	Export: "exp"
};

export const FormType = Object.freeze({
	Create: Symbol(1000),
	Edit: Symbol(1001)
});

export const AnnouncementType = Object.freeze({
	Informational: 1,
	Acknowledgement: 2,
	Promotional: 3
});

export const ApiHeaders = Object.freeze({
	WebsiteId: "ahwid"
});