import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import SiteHeader from "../../components/SiteHeader";
import { Backdrop, CircularProgress } from "@mui/material";
import SmcTecfinitySalesOrderCreateEdit from "../../components/SmcTecfinitySalesOrderCreateEdit";
import { fetchDetailedSalesOrderData } from "../PageFunctions/tecfinityFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const SmcTecfinitySalesOrderCreateEditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {orderId} = useParams();
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		logout
	} = useAuth0();
	const navigate = useNavigate();
	const [salesOrderDetail, setSalesOrderDetail] = useState(null);

	const [fetchSalesOrderDataCallStatus, setFetchSalesOrderDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoHomePage = () => navigate("/");
	const gotoResponsesPage = () => navigate("/smc-tecfinity-sales-orders");
	const reloadPage = () => navigate(0);

	const fetchSalesOrderDataAsync = async () => {
		if (!!orderId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchDetailedSalesOrderData({
				auth0Token,
				orderId,
				setSalesOrderDetail,
				setStatusInProgress: () => setFetchSalesOrderDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchSalesOrderDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchSalesOrderDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchSalesOrderDataCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const authorizedPageLoad = async () => {
		await Promise.all([
			fetchSalesOrderDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				logout().then(() => gotoHomePage());
			} else {
				authorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isLoading, isAuthenticated, logout]);

	const allLoadingStates = [
		fetchSalesOrderDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<SmcTecfinitySalesOrderCreateEdit
				onBackClick={gotoResponsesPage}
				salesOrderDetail={salesOrderDetail}
			/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};
export default SmcTecfinitySalesOrderCreateEditPage;