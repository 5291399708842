import { Accordion, Button, Grid, Icon, Input, Menu, Pagination, Popup, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import React, { useState } from "react";


const Products = ({
	onBackClick,
	onApplyFiltersClick,
	onClearFiltersClick,
	gotoCreatePage,
	onPageNumberChange,
	onFilterChange,
	pageNumber,
	pageSize,
	totalResults,
	products,
	filters,
	closeModals
}) => {

	const [showFilters, setShowFilters] = useState(true);

	const onFiltersClick = () => {
		setShowFilters(!showFilters);
	};

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">Products</div>
			</div>
			<Grid container>
				<Grid.Row>
					<Grid.Column width={16} verticalAlign="middle">
						<Button floated="left"
						        size="tiny"
						        type="button"
						        className="blue-basic-button borderless"
						        onClick={onBackClick}>
							<Icon name="arrow left"/>
							Back
						</Button>
					</Grid.Column>
				</Grid.Row>
				{!!filters &&
					<Grid.Row>
						<Grid.Column width={16}>
							<Accordion fluid styled>
								<Accordion.Title
									active={showFilters}
									onClick={onFiltersClick}
								>
									<Icon name="dropdown"/>
									Filters
								</Accordion.Title>
								<Accordion.Content active={showFilters}>
									<Grid container>
										<Grid.Row>
											<Grid.Column width={8}>
												<label>Sku</label>
												<Input
													name="sku"
													value={filters.sku}
													onChange={onFilterChange}
													fluid
													placeholder="Sku"/>
											</Grid.Column>
											<Grid.Column width={8}>
												<label>Name</label>
												<Input
													name="name"
													value={filters.name}
													onChange={onFilterChange}
													fluid
													placeholder="Name"/>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column width={16}>
												<Button floated="right"
												        type="button"
												        onClick={onClearFiltersClick}>
													<Icon name="trash"/>
													Clear
												</Button>
												<Button floated="right"
												        type="button"
												        onClick={onApplyFiltersClick}
												        className="blue-button">
													<Icon name="search"/>
													Filter
												</Button>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Accordion.Content>
							</Accordion>
						</Grid.Column>
					</Grid.Row>
				}
				{!!products &&
					<>
						<Grid.Row>
							<Grid.Column width={16}>
								<Table sortable selectable>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Sku
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Name
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Category
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Type
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top" rowSpan="1">
												Vendor
											</Table.HeaderCell>
											<Table.HeaderCell className="sticky-top"
											                  rowSpan="1">
												Actions
											</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{!!products && products.map((c, index) => {
											let actionRequired = false;

											return (
												<Table.Row key={index}>
													<Table.Cell>
														<Link to={`/product/${c.sku}`}>
															{c.sku}
														</Link>
													</Table.Cell>
													<Table.Cell>{c.name}</Table.Cell>
													<Table.Cell>{c.productCategory?.replaceAll("_", " ")}</Table.Cell>
													<Table.Cell>
														{c.productType?.replaceAll("_", " ")}
													</Table.Cell>
													<Table.Cell>{c.vendor}</Table.Cell>
													<Table.Cell textAlign="center">
														<Popup flowing
														       basic
														       hoverable
														       trigger={<Icon
															       color={actionRequired ? "red" : "black"}
															       name={actionRequired ? "exclamation circle" : "ellipsis vertical"}/>}>
															<Menu vertical className="argus-menu" borderless fluid>

															</Menu>
														</Popup>

													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={16} textAlign="right">
								{totalResults && totalResults > 0 &&
									<Pagination
										activePage={pageNumber}
										boundaryRange={1}
										size="mini"
										onPageChange={onPageNumberChange}
										siblingRange={1}
										totalPages={Math.ceil(totalResults / pageSize)}
									/>
								}
							</Grid.Column>
						</Grid.Row>
					</>
				}
			</Grid>
		</div>
	);
};

export default Products;