import React from "react";
import { Button, Container, Grid, Header } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";

const Home = ({
	loading
}) => {
	const {isAuthenticated, loginWithRedirect} = useAuth0();
	return (
		<Container className="argus-full-page-container" fluid>
			<Grid centered padded verticalAlign="middle" className="mt-5">
				<Grid.Row>
					{isAuthenticated === false && loading === false &&
						<Grid.Column width={16} textAlign="center">
							<Header as="h1">Login to your account</Header>
						</Grid.Column>
					}
					{isAuthenticated === true && loading === false &&
						<Grid.Column width={16} textAlign="center">
							<Header as="h1">You are logged in</Header>
						</Grid.Column>
					}
				</Grid.Row>
				{isAuthenticated === false && loading === false &&
					<Grid.Row>
						<Grid.Column width={16} textAlign="center">
							<Button className="blue-button"
							        type="button"
							        onClick={loginWithRedirect}>
								Login
							</Button>
						</Grid.Column>
					</Grid.Row>
				}
			</Grid>
		</Container>
	);
};
export default Home;