import {
	getDetailedOrder,
	getOrdersByUserId,
	getOrdersOverview,
	putOrderPaymentToPaid
} from "../../api/orderController";
import { sortBy } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserAddresses } from "../../api/addressController";

export const fetchOrdersData = async ({
	auth0Token,
	pageSize,
	pageNumber,
	setOrders,
	setPageNumber,
	setTotalResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getOrdersOverview({
			auth0Token,
			pageSize,
			pageNumber
		});

		if (response?.success) {
			const orders = response.data;
			const total = response.total;
			const skip = response.skip;
			const limit = response.limit;

			setOrders(orders);
			setStatusSuccess();
			setPageNumber(skip + 1);
			setTotalResults(response.total);
		}
		else {
			setOrders([]);
			setStatusFailed();
			setTotalResults(0);
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchUserAddressesData = async ({
	auth0Token,
	userId,
	setAddresses,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getUserAddresses({userId, auth0Token});

		if (!!response?.success) {
			setAddresses(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get user addresses.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get user addresses.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchOrdersDataByUserId = async ({
	auth0Token,
	userId,
	setOrders,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getOrdersByUserId({userId, auth0Token});

		if (!!response?.success) {
			setOrders(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get user orders.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get user orders.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const fetchDetailedOrderData = async ({
	auth0Token,
	orderId,
	setOrder,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getDetailedOrder({orderId, auth0Token});

		if (!!response?.success) {
			setOrder(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
			toast("Could not get order.", {
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
		toast("Could not get order.", {
			type: "error",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined
		});
	}
};

export const updateOrderPaymentToPaid = async ({
	auth0Token,
	orderId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await putOrderPaymentToPaid({orderId, auth0Token});
		if (response.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};