import React from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import { Grid, Image, Table } from 'semantic-ui-react';
import "./style.css";
import ArgusLogo from "../../img/logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const SmcTecfinitySalesOrderRequestModal = ({
	open,
	setClose,
	salesOrderRequest
}) => {

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center', // Centers content vertically in the modal
		alignItems: 'center', // Centers content horizontally
		padding: 4,
		boxShadow: 24,
		bgcolor: 'white',
		minWidth: '30vw',
		minHeight: '30vw'
	};
	const closeModal = () => {
		setClose();
	};

	return (
		<Modal open={open}>
			<Box sx={modalStyle}>
				<IconButton onClick={closeModal} style={{position: 'absolute', top: 0, right: 0}}>
					<CloseIcon/>
				</IconButton>
				<Grid>
					<Grid.Row textAlign="center">
						<Grid.Column width={16} textAlign="center">
							<Image src={ArgusLogo} size="small" fluid centered/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<div className="argus-modal-title">SMC Sales Order Request</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<div className="argus-modal-text">
								These are the details that were sent through to Tecfinity:
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row width={16} verticalAlign="middle" textAlign="center">
						<Grid.Column width={16}>
							<div className="scrollable-y" style={{maxHeight: "50vh"}}>
								<Table basic="very" collapsing className="borderless-table">
									<Table.Body>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Request Date</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{moment(salesOrderRequest.requestDate).format("YYYY-MM-DD | HH:mm:ss")}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Branch</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.branch === "" ? "Empty" : (salesOrderRequest.branch === null ? "NULL" : salesOrderRequest.branch)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Action</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.action === "" ? "Empty" : (salesOrderRequest.action === null ? "NULL" : salesOrderRequest.action)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Account Number</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.accountNumber === "" ? "Empty" : (salesOrderRequest.accountNumber === null ? "NULL" : salesOrderRequest.accountNumber)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Ship Via</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.shipVia === "" ? "Empty" : (salesOrderRequest.shipVia === null ? "NULL" : salesOrderRequest.shipVia)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Sales Rep</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.salesRep === "" ? "Empty" : (salesOrderRequest.salesRep === null ? "NULL" : salesOrderRequest.salesRep)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Name</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryName === "" ? "(Empty)" : (salesOrderRequest.deliveryName === null ? "(NULL)" : salesOrderRequest.deliveryName)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Surname</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliverySurname === "" ? "(Empty)" : (salesOrderRequest.deliverySurname === null ? "(NULL)" : salesOrderRequest.deliverySurname)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Company</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryCompany === "" ? "(Empty)" : (salesOrderRequest.deliveryCompany === null ? "(NULL)" : salesOrderRequest.deliveryCompany)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Address Line 1</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryAddressLine1 === "" ? "(Empty)" : (salesOrderRequest.deliveryAddressLine1 === null ? "(NULL)" : salesOrderRequest.deliveryAddressLine1)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Address Line 2</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryAddressLine2 === "" ? "(Empty)" : (salesOrderRequest.deliveryAddressLine2 === null ? "(NULL)" : salesOrderRequest.deliveryAddressLine2)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Address Line 3</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryAddressLine3 === "" ? "(Empty)" : (salesOrderRequest.deliveryAddressLine3 === null ? "(NULL)" : salesOrderRequest.deliveryAddressLine3)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery City</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryCity === "" ? "(Empty)" : (salesOrderRequest.deliveryCity === null ? "(NULL)" : salesOrderRequest.deliveryCity)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Delivery Province</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.deliveryProvince === "" ? "(Empty)" : (salesOrderRequest.deliveryProvince === null ? "(NULL)" : salesOrderRequest.deliveryProvince)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Name</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingName === "" ? "(Empty)" : (salesOrderRequest.billingName === null ? "(NULL)" : salesOrderRequest.billingName)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Surname</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingSurname === "" ? "(Empty)" : (salesOrderRequest.billingSurname === null ? "(NULL)" : salesOrderRequest.billingSurname)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Company</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingCompany === "" ? "(Empty)" : (salesOrderRequest.billingCompany === null ? "(NULL)" : salesOrderRequest.billingCompany)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Address Line 1</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingAddressLine1 === "" ? "(Empty)" : (salesOrderRequest.billingAddressLine1 === null ? "(NULL)" : salesOrderRequest.billingAddressLine1)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Address Line 2</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingAddressLine2 === "" ? "(Empty)" : (salesOrderRequest.billingAddressLine2 === null ? "(NULL)" : salesOrderRequest.billingAddressLine2)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Address Line 3</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingAddressLine3 === "" ? "(Empty)" : (salesOrderRequest.billingAddressLine3 === null ? "(NULL)" : salesOrderRequest.billingAddressLine3)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing City</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingCity === "" ? "(Empty)" : (salesOrderRequest.billingCity === null ? "(NULL)" : salesOrderRequest.billingCity)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Billing Province</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.billingProvince === "" ? "(Empty)" : (salesOrderRequest.billingProvince === null ? "(NULL)" : salesOrderRequest.billingProvince)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>OrderId</strong>
											</Table.Cell>
											<Table.Cell className="borderless">{salesOrderRequest.orderId}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>OrderKey</strong>
											</Table.Cell>
											<Table.Cell className="borderless">{salesOrderRequest.orderKey}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Order Status</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.orderStatus}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Order Total</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.orderTotal}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Sku Number</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.skuNumber}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Sku Description</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.skuDescription === "" ? "(Empty)" : (salesOrderRequest.skuDescription === null ? "(NULL)" : salesOrderRequest.skuDescription)}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Sku Quantity</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.skuQuantity}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Sku Cost</strong>
											</Table.Cell>
											<Table.Cell className="borderless">{salesOrderRequest.skuCost}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Customer Email</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.customerEmail}</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell className="borderless" textAlign="right">
												<strong>Customer Telephone Number</strong>
											</Table.Cell>
											<Table.Cell
												className="borderless">{salesOrderRequest.customerTelephoneNumber}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Box>
		</Modal>
	);
};

export default SmcTecfinitySalesOrderRequestModal;
