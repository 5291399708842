import React, { Fragment, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ApiCallStatus, LoggedInStatus } from "../../utils/constants";
import { Backdrop, CircularProgress } from "@mui/material";
import SiteHeader from "../../components/SiteHeader";
import { checkIsLoggedIn, clearCookies } from "../PageFunctions/commonFunctions";
import { fetchSmcTecfinitySalesOrderResponseData } from "../PageFunctions/tecfinityFunctions";
import SmcTecfinitySalesOrders from "../../components/SmcTecfinitySalesOrders";
import { cloneDeep } from "lodash";
import { fetchCurrentUserData } from "../PageFunctions/userFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const ModalName = Object.freeze({
	TecfinityLink: Symbol(1),
	TecfinityDeLink: Symbol(2),
	AccountActivation: Symbol(3),
	AccountDeactivation: Symbol(4)
});

const SmcTecfinitySalesOrderResponsesPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const navigate = useNavigate();
	const [auth0Token, setAuth0Token] = useState(null);
	const {
		user,
		isLoading,
		isAuthenticated,
		getAccessTokenSilently
	} = useAuth0();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [responses, setResponses] = useState([]);
	const [responseToEdit, setResponseToEdit] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(18);

	const [openModal, setOpenModal] = useState(null);

	const [fetchResponsesDataCallStatus, setFetchResponsesDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const gotoHomePage = () => navigate("/");
	const reloadPage = () => navigate(0);

	const fetchSmcTecfinitySalesOrderResponseDataAsync = async (startDate, endDate, pageNumberParam, pageSizeParam) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchSmcTecfinitySalesOrderResponseData({
			auth0Token,
			startDate,
			endDate,
			pageNumber: pageNumberParam,
			pageSize: pageSizeParam,
			setResponses,
			setPageNumber,
			setTotalResults,
			setStatusInProgress: () => setFetchResponsesDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchResponsesDataCallStatus(ApiCallStatus.Succeeded),
			setStatusError: () => setFetchResponsesDataCallStatus(ApiCallStatus.Error),
			setStatusFailed: () => setFetchResponsesDataCallStatus(ApiCallStatus.Failed)
		});
	};

	const authorizedPageLoad = async () => {
		let pageNumberParam = Number(searchParams.get('pageNumber'));
		if (!pageNumberParam || isNaN(pageNumberParam)) {
			pageNumberParam = 1;
		}
		const pageSizeParam = 18;
		const startDate = searchParams.get("startDate");
		const endDate = searchParams.get("endDate");

		await Promise.all([
			fetchSmcTecfinitySalesOrderResponseDataAsync(startDate, endDate, pageNumberParam, pageSizeParam)
		]);
	};

	const onBackClick = () => {
		return navigate(-1);
	};

	const updateUrlQueryValue = async (key, value) => {
		setFetchResponsesDataCallStatus(ApiCallStatus.NotStarted);
		const currentParams = Object.fromEntries([...searchParams]);
		const paramsClone = cloneDeep(currentParams);

		paramsClone[key] = value;
		await navigate({
			pathname: location.pathname,
			search: `?${createSearchParams(paramsClone)}`
		});
	};

	const onPageNumberChange = async (_, {activePage}) => {
		let newPageNumber = Number(activePage);
		if (!isNaN(newPageNumber)) {
			await updateUrlQueryValue("pageNumber", Number(activePage));
		}
	};

	const openTecfinityAccountLinkModal = () => {
		setOpenModal(ModalName.TecfinityLink);
	};

	const openTecfinityAccountDeLinkModal = () => {
		setOpenModal(ModalName.TecfinityDeLink);
	};

	const openAccountActivationModal = () => {
		setOpenModal(ModalName.AccountActivation);
	};

	const openAccountDeactivationModal = () => {
		setOpenModal(ModalName.AccountDeactivation);
	};

	const closeModals = () => {
		setOpenModal(null);
	};

	const handleTecfinityAccountLinkModalClose = () => {

	};

	useEffect(() => {
		if (authCheckComplete === true) {
			if (!auth0Token) {
				logout().then(() => gotoHomePage());
			}
			else if (!!auth0Token) {
				authorizedPageLoad();
			}
		}
	}, [auth0Token, authCheckComplete]);

	useEffect(() => {
		scrollTop();
		let pageNumberParam = Number(searchParams.get('pageNumber'));
		const pageSizeParam = 18;

		if (!pageNumberParam || isNaN(pageNumberParam)) {
			return;
		}

		const startDate = searchParams.get("startDate");
		const endDate = searchParams.get("endDate");

		fetchSmcTecfinitySalesOrderResponseDataAsync(startDate, endDate, pageNumberParam, pageSizeParam);
	}, [searchParams]);

	const allLoadingStates = [
		fetchResponsesDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true || genericDataLoaded === false;;

	return (
		<Fragment>
			<SmcTecfinitySalesOrders
				responses={responses}
				userToEdit={responseToEdit}
				closeModals={closeModals}
				setResponseToEdit={setResponseToEdit}
				totalResults={totalResults}
				pageNumber={pageNumber}
				pageSize={pageSize}
				onPageNumberChange={onPageNumberChange}
				onBackClick={gotoHomePage}/>
			<Backdrop
				sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	);
};

export default SmcTecfinitySalesOrderResponsesPage;