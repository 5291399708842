import React, { useState } from "react";
import { updateOrderPaymentToPaid } from "../../main-component/PageFunctions/orderFunctions";
import { Box, Modal } from "@mui/material";
import { Button, Form, Grid, Header, Icon, Image } from "semantic-ui-react";
import ArgusLogo from "../../img/logo.svg";
import { SouthAfricanRand } from "../../utils/helpers";
import { useAuth0 } from "@auth0/auth0-react";


const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minHeight: '50%',
	width: "25%",
	bgcolor: '#FFFFFF',
	boxShadow: 24,
	p: 4
};

const modalState = {
	orderDetails: 1,
	inProgress: 2,
	orderMarkedAsPaid: 3,
	orderNotMarkedAsPaid: 4,
	error: 5
};

const MarkOrderAsPaidModal = ({
	open,
	setOpen,
	setClose,
	handleModalClose,
	orderToEdit,
}) => {
	const {isAuthenticated, getAccessTokenSilently} = useAuth0();
	const [currentModalState, setCurrentModalState] = useState(modalState.orderDetails);
	const [modalError, setModalError] = useState("");

	const editOrderAsync = async (orderId) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateOrderPaymentToPaid({
			auth0Token,
			orderId,
			setStatusInProgress: () => setCurrentModalState(modalState.inProgress),
			setStatusSuccess: () => setCurrentModalState(modalState.orderMarkedAsPaid),
			setStatusFailed: () => setCurrentModalState(modalState.orderNotMarkedAsPaid),
			setStatusError: () =>  { setCurrentModalState(modalState.error); setModalError("Could not mark order as paid"); }
		});
	};

	const resetModal = () => {
		setCurrentModalState(modalState.orderDetails);
		setModalError("");
	};

	const closeModal = () => {
		resetModal();
		setClose();
	};

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Grid>
					<Grid.Row textAlign="center">
						<Grid.Column width={16} textAlign="center">
							<Image src={ArgusLogo} size="small" fluid centered/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="center">
							<div className="argus-modal-title">Mark Order As Paid</div>
						</Grid.Column>
					</Grid.Row>
					{(currentModalState === modalState.orderDetails || currentModalState === modalState.inProgress) &&
						<>
							<Grid.Row>
								<Grid.Column width={16}>
									<div className="argus-modal-text">
										Are you sure you want to mark this order as paid:
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16}>
									{!!orderToEdit.orderNumber &&
										<p><b>Order Number: </b> &nbsp; {orderToEdit.orderNumber}</p>
									}
									{!!orderToEdit.customerName &&
										<p><b>Customer: </b> &nbsp; {orderToEdit.customerName}</p>
									}
									{!!orderToEdit.salesPersonName &&
										<p><b>Sales Person: </b> &nbsp; {orderToEdit.salesPersonName}</p>
									}
									{!!orderToEdit.total &&
										<p><b>Order Total: </b> &nbsp; {SouthAfricanRand.format(orderToEdit.total)}</p>
									}
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        className="blue-button"
									        floated="right"
									        disabled={currentModalState === modalState.inProgress}
									        loading={currentModalState === modalState.inProgress}
									        onClick={() => editOrderAsync(orderToEdit.orderId)}>
										Confirm
									</Button>
									<Button type="button"
									        floated="right"
									        disabled={currentModalState === modalState.inProgress}
									        loading={currentModalState === modalState.inProgress}
									        onClick={closeModal}>
										Cancel
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.orderMarkedAsPaid &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element"
									      name="check circle outline"
									      color="green"
									      size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Success</Header>
								</Grid.Column>
								<Grid.Column width={16} textAlign="center">
									<p className="b1">Order marked as paid.</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.orderNotMarkedAsPaid &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="times circle outline" color="red"
									      size="big"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Failed</Header>
								</Grid.Column>
								<Grid.Column width={16}>
									<Header as="h3" textAlign="center">
										Could not mark order as paid.
									</Header>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
					{currentModalState === modalState.error && !!modalError &&
						<>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Icon className="center-element" name="ban" color="red" size="massive"/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row verticalAlign="middle" textAlign="center">
								<Grid.Column width={16}>
									<Header as="h1" textAlign="center">Error</Header>
								</Grid.Column>
								<Grid.Column width={16} textAlign="center">
									<p className="b1">{modalError}</p>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={16} textAlign="right">
									<Button type="button"
									        floated="right"
									        onClick={closeModal}>
										Close
									</Button>
								</Grid.Column>
							</Grid.Row>
						</>
					}
				</Grid>
			</Box>
		</Modal>
	);
};

export default MarkOrderAsPaidModal;