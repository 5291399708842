import React, { useState } from 'react';
import { Button, Grid, Header, Icon, Segment, Table } from "semantic-ui-react";
import "./style.css";
import moment from "moment";
import SmcTecfinitySalesOrderRequestModal from "../../components/SmcTecfinitySalesOrderRequestModal";

const SmcTecfinitySalesOrderCreateEditForm = ({
	salesOrderDetail,
	goBack
}) => {
	const [salesOrderRequestDetails, setSalesOrderRequestDetails] = useState(null);

	const onViewMoreClick = (request) => {
		setSalesOrderRequestDetails(request);
	};

	const closeModal = () => {
		setSalesOrderRequestDetails(null);
	};

	return (
		<>
			<Segment padded>
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16} verticalAlign="middle">
							<Button floated="left"
							        size="tiny"
							        type="button"
							        className="blue-basic-button borderless"
							        onClick={goBack}>
								<Icon name="arrow left"/>
								Back to sales orders
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<Table basic="very" collapsing className="borderless-table">
								<Table.Body>
									<Table.Row>
										<Table.Cell className="borderless" textAlign="right">
											<strong>OrderId</strong>
										</Table.Cell>
										<Table.Cell className="borderless">{salesOrderDetail.orderId}</Table.Cell>
									</Table.Row>
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						{!!salesOrderDetail?.requests &&
							<Grid.Column width={16}>
								<Grid>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												Requests
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={16}>
											<Table>
												<Table.Header>
													<Table.HeaderCell>Date</Table.HeaderCell>
													<Table.HeaderCell>Branch</Table.HeaderCell>
													<Table.HeaderCell>Action</Table.HeaderCell>
													<Table.HeaderCell>Account Number</Table.HeaderCell>
													<Table.HeaderCell>Ship Via</Table.HeaderCell>
													<Table.HeaderCell>Sales Rep</Table.HeaderCell>
													<Table.HeaderCell>Actions</Table.HeaderCell>
												</Table.Header>
												<Table.Body>
													{salesOrderDetail.requests.map((r, index) => {
														return <Table.Row key={index}>
															<Table.Cell>{moment(r.requestDate).format("YYYY-MM-DD | HH:mm:ss")}</Table.Cell>
															<Table.Cell>{r.branch}</Table.Cell>
															<Table.Cell>{r.action}</Table.Cell>
															<Table.Cell>{r.accountNumber}</Table.Cell>
															<Table.Cell>{r.shipVia}</Table.Cell>
															<Table.Cell>{r.salesRep}</Table.Cell>
															<Table.Cell>
																<Button type="button"
																        className="blue-basic-button borderless"
																        compact
																        onClick={() => onViewMoreClick(r)}>
																	View more...
																</Button>
															</Table.Cell>
														</Table.Row>;
													})}
												</Table.Body>
											</Table>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
						}
					</Grid.Row>
					<Grid.Row>
						{!!salesOrderDetail?.responses &&
							<Grid.Column width={16}>
								<Grid>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												Responses
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={16}>
											<Table>
												<Table.Header>
													<Table.HeaderCell>Date</Table.HeaderCell>
													<Table.HeaderCell>Response</Table.HeaderCell>
												</Table.Header>
												<Table.Body>
													{salesOrderDetail.responses.map((r, index) => {
														return <Table.Row key={index}>
															<Table.Cell>{moment(r.responseDate).format("YYYY-MM-DD | HH:mm:ss")}</Table.Cell>
															<Table.Cell>{r.response}</Table.Cell>
														</Table.Row>;
													})}
												</Table.Body>
											</Table>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
						}
					</Grid.Row>
				</Grid>
			</Segment>
			{!!salesOrderRequestDetails &&
				<SmcTecfinitySalesOrderRequestModal
					salesOrderRequest={salesOrderRequestDetails}
					open={!!salesOrderRequestDetails}
					setClose={closeModal}

				/>
			}
		</>
	);

};

export default SmcTecfinitySalesOrderCreateEditForm;