import React from "react";
import { Button, Grid, Icon, Menu, Pagination, Popup, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import moment from "moment";


const SmcTecfinitySalesOrders = ({
	responses,
	pageNumber,
	pageSize,
	totalResults,
	onBackClick,
	onPageNumberChange
}) => {

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">SMC Tecfinity Sales Orders</div>
			</div>
			{!!responses &&
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16}>
							<Button compact
							        size="tiny"
							        className="blue-basic-button borderless"
							        onClick={onBackClick}>
								<Icon name="arrow left"/>
								Back
							</Button>

						</Grid.Column>
					</Grid.Row>
					<Grid.Row verticalAlign="middle" textAlign="center">
						<Grid.Column textAlign="center" width={16}>
							<Table sortable selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell className="sticky-top" rowSpan="1">
											Order #
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top" rowSpan="1">
											Response date
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Success
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Actions
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{!!responses && responses.map((c, index) => {
										return (
											<Table.Row key={index}>
												<Table.Cell>
													<Link
														to={`/smc-tecfinity-sales-order/${c.orderId}`}>{c.orderId}</Link>
												</Table.Cell>
												<Table.Cell>{moment(c.responseDate).format("DD MMM YYYY | HH:mm:ss")}</Table.Cell>
												<Table.Cell>
													{c.success ?
														<Icon name="check" color="green"/> :
														<Icon name="times" color="red"/>
													}
												</Table.Cell>
												<Table.Cell textAlign="center">
													<Popup flowing
													       basic
													       hoverable
													       trigger={<Icon
														       color="black"
														       name="ellipsis vertical"/>}>
														<Menu vertical className="argus-menu" borderless fluid>

														</Menu>
													</Popup>

												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="right">
							{totalResults && totalResults > 0 &&
								<Pagination
									activePage={pageNumber}
									boundaryRange={1}
									size="mini"
									onPageChange={onPageNumberChange}
									siblingRange={1}
									totalPages={Math.ceil(totalResults / pageSize)}
								/>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>

			}
		</div>
	);
};

export default SmcTecfinitySalesOrders;