import { Button, Grid, Icon, Menu, Pagination, Popup, Table } from "semantic-ui-react";
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { SouthAfricanRand } from "../../utils/helpers";
import { OrderPaymentStatus } from "../../utils/constants";
import MarkOrderAsPaidModal from "../MarkOrderAsPaidModal";


const Orders = ({
	onBackClick,
	onPageNumberChange,
	pageNumber,
	pageSize,
	totalResults,
	orders,
	orderToEdit,
	setOrderToEdit,
	showMarkAsPaidModal,
	openMarkAsPaidModal,
	closeModals,
	handleMarkAsPaidModalClose
}) => {

	const handleOpenMarkOrderAsPaidModal = order => {
		if (!!order) {
			setOrderToEdit(order);
			openMarkAsPaidModal();
		}
	};

	return (
		<div className="argus-min-height">
			<div className="argus-title-background mb-5">
				<div className="argus-title">Orders</div>
			</div>
			{!!orders &&
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16}>
							<Button compact
							        size="tiny"
							        className="blue-basic-button borderless"
							        onClick={onBackClick}>
								<Icon name="arrow left"/>
								Back
							</Button>

						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16}>
							<Table sortable selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell className="sticky-top" rowSpan="1">
											#
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Customer
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Date
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Sales Person
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Status
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Payment
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Amount
										</Table.HeaderCell>
										<Table.HeaderCell className="sticky-top"
										                  rowSpan="1">
											Actions
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{!!orders && orders.map((c, index) => {
										let actionRequired = false;

										return (
											<Table.Row key={index}>
												<Table.Cell>
													<Link to={`/order/${c.orderId}`}>
														{c.orderNumber}
													</Link>
												</Table.Cell>
												<Table.Cell>{c.customerName}</Table.Cell>
												<Table.Cell>{moment(c.dateCreated).format("YYYY-MM-DD | HH:mm:ss")}</Table.Cell>
												<Table.Cell>{c.salesPersonName}</Table.Cell>
												<Table.Cell>{c.orderStatus}</Table.Cell>
												<Table.Cell>{c.paymentStatus}</Table.Cell>
												<Table.Cell>{SouthAfricanRand.format(c.total)}</Table.Cell>
												<Table.Cell textAlign="center">
													<Popup flowing
													       basic
													       hoverable
													       trigger={<Icon
														       color={actionRequired ? "red" : "black"}
														       name={actionRequired ? "exclamation circle" : "ellipsis vertical"}/>}>
														<Menu vertical className="argus-menu" borderless fluid>
															{!!c.paymentStatus && c.paymentStatusId !== OrderPaymentStatus.Paid &&
																<Menu.Item to="#"
																           as={Link}
																           onClick={() => handleOpenMarkOrderAsPaidModal(c)}>
																	Mark as paid
																</Menu.Item>
															}
														</Menu>
													</Popup>

												</Table.Cell>
											</Table.Row>
										);
									})}
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width={16} textAlign="right">
							{totalResults && totalResults > 0 &&
								<Pagination
									activePage={pageNumber}
									boundaryRange={1}
									size="mini"
									onPageChange={onPageNumberChange}
									siblingRange={1}
									totalPages={Math.ceil(totalResults / pageSize)}
								/>
							}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			}
			{!!showMarkAsPaidModal &&
				<MarkOrderAsPaidModal
					open={showMarkAsPaidModal}
					setOpen={openMarkAsPaidModal}
					setClose={closeModals}
					handleModalClose={handleMarkAsPaidModalClose}
					orderToEdit={orderToEdit}
				/>
			}
		</div>
	);
};

export default Orders;