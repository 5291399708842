import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../img/logo.svg";
import "./style.css";
import { Button, Dropdown, Grid, Image, Menu } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";

const SiteHeader = () => {
	const {isLoading, logout, isAuthenticated, loginWithRedirect} = useAuth0();
	const [currentPage, setCurrentPage] = useState(null);
	const location = useLocation();

	const pagesToExclude = ["login", "register", "account-verification", "forgot-password"];

	useEffect(() => {
		const pathName = location.pathname;
		const pageName = pathName.split("/").pop();
		setCurrentPage(pageName);
	}, [location]);

	const performLogout = async () => {
		await logout({logoutParams: {returnTo: window.location.origin}});
	};

	return (
		<>
			{!pagesToExclude.includes(currentPage) &&
				<header className="header-container">
					<Grid padded>
						<Grid.Row className="mb-4" verticalAlign="middle">
							<Grid.Column width={3}>
								<Image as={Link} to="/" src={Logo} alt="Argus" size="small"/>
							</Grid.Column>
							<Grid.Column width={7}>
								<Menu className="argus-menu" borderless fluid>
									<Menu.Menu position="left">
										{isAuthenticated === true &&
											<>
												<Menu.Item
													name="Users"
													as={Link}
													to="/users"
												>
													Users
												</Menu.Item>
												<Menu.Item
													name="Orders"
													as={Link}
													to="/orders"
												>
													Orders
												</Menu.Item>
												<Menu.Item
													name="Products"
													as={Link}
													to="/products"
												>
													Products
												</Menu.Item>
												<Dropdown item text="CMS">
													<Dropdown.Menu>
														<Dropdown.Item as={Link}
														               to="/announcements">Announcements</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
												<Dropdown item text="SMC">
													<Dropdown.Menu>
														<Dropdown.Item as={Link}
														               to="/smc-tecfinity-sales-orders">Sales
															Orders</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</>
										}
									</Menu.Menu>
								</Menu>
							</Grid.Column>
							<Grid.Column width={6}>
								<Menu className="argus-menu" borderless fluid>
									<Menu.Menu position="right">
										{isAuthenticated === false &&
											<>
												{isLoading === false &&
													<Menu.Item
														name="Login"
														onClick={loginWithRedirect}
													>
														Login
													</Menu.Item>
												}
											</>
										}
										{isAuthenticated === true &&
											<>
												<Dropdown item text="My Account">
													<Dropdown.Menu>
														{isLoading === false &&
															<Dropdown.Item as={Button}
															               onClick={performLogout}>
																Sign out
															</Dropdown.Item>
														}
													</Dropdown.Menu>
												</Dropdown>

											</>
										}
									</Menu.Menu>
								</Menu>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</header>
			}
		</>
	);
};

export default SiteHeader;
