import React from "react";
import { Grid } from "semantic-ui-react";
import SmcTecfinitySalesOrderCreateEditForm from "../../forms/SmcTecfinitySalesOrderCreateEditForm";

const SmcTecfinitySalesOrderCreateEdit = ({
	salesOrderDetail,
	onBackClick
}) => {

	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					Sales Order
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{!!salesOrderDetail &&
						<SmcTecfinitySalesOrderCreateEditForm
							salesOrderDetail={salesOrderDetail}
							goBack={onBackClick}/>
					}
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default SmcTecfinitySalesOrderCreateEdit;