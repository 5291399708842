import React from "react";
import { Route, Routes } from "react-router-dom";

import Homepage from "../HomePage";
import ErrorPage from "../ErrorPage";
import OrdersPage from "../OrdersPage";
import UsersPage from "../UsersPage";
import AnnouncementEditPage from "../AnnouncementEditPage";
import AnnouncementsPage from "../AnnouncementsPage";
import UserCreateEditPage from "../UserCreateEditPage";
import OrderCreateEditPage from "../OrderCreateEditPage";
import AnnouncementCreatePage from "../AnnouncementCreatePage";
import ProductsPage from "../ProductsPage";
import ProductCreateEditPage from "../ProductCreateEditPage";
import SmcTecfinitySalesOrderResponsesPage from "../SmcTecfinitySalesOrderResponsesPage";
import SmcTecfinitySalesOrderCreateEditPage from "../SmcTecfinitySalesOrderCreateEditPage";
import PageWrapper from "../PageWrapper";

const AllRoute = () => {
	return (
		<Routes>
			<Route exact path="/" element={<PageWrapper><Homepage/></PageWrapper>}/>
			<Route exact path="/home" element={<PageWrapper><Homepage/></PageWrapper>}/>
			<Route exact path="/error" element={<ErrorPage/>}/>
			<Route exact path="/orders" element={<PageWrapper><OrdersPage/></PageWrapper>}/>
			<Route exact path="/order/:orderId" element={<PageWrapper><OrderCreateEditPage/></PageWrapper>}/>
			<Route exact path="/users" element={<PageWrapper><UsersPage/></PageWrapper>}/>
			<Route exact path="/user/:userId" element={<PageWrapper><UserCreateEditPage/></PageWrapper>}/>
			<Route exact path="/announcements" element={<PageWrapper><AnnouncementsPage/></PageWrapper>}/>
			<Route exact path="/announcement/create" element={<PageWrapper><AnnouncementCreatePage/></PageWrapper>}/>
			<Route exact path="/announcement/:announcementId" element={<PageWrapper><AnnouncementEditPage/></PageWrapper>}/>
			<Route exact path="/products" element={<PageWrapper><ProductsPage/></PageWrapper>}/>
			<Route exact path="/product/:sku" element={<PageWrapper><ProductCreateEditPage/></PageWrapper>}/>
			<Route exact path="/smc-tecfinity-sales-orders" element={<PageWrapper><SmcTecfinitySalesOrderResponsesPage/></PageWrapper>}/>
			<Route exact path="/smc-tecfinity-sales-order/:orderId" element={<PageWrapper><SmcTecfinitySalesOrderCreateEditPage/></PageWrapper>}/>
			<Route path="*" element={<ErrorPage/>}/>
		</Routes>
	);
};

export default AllRoute;
