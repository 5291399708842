import React from "react";
import { FormType } from "../../utils/constants";
import { Grid } from "semantic-ui-react";
import UserCreateEditForm from "../../forms/UserCreateEditForm";

const UserCreateEdit = ({
	user,
	orders,
	addresses,
	countries,
	provinces,
	salesPeople,
	formType,
	onBackClick,
	submit
}) => {
	return (
		<div>
			<div className="argus-title-background mb-5">
				<div className="argus-title">
					User
				</div>
			</div>
			<Grid container>
				<Grid.Row verticalAlign="middle" textAlign="center">
					{formType === FormType.Edit && !!user &&
						<UserCreateEditForm
							formType={formType}
							user={user}
							orders={orders}
							addresses={addresses}
							provinces={provinces}
							countries={countries}
							salesPeople={salesPeople}
							goBack={onBackClick}
							submit={submit}/>
					}
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default UserCreateEdit;