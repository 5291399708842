import React from 'react';
import DOMPurify from 'dompurify';
import { Button, Form, Grid, Header, Icon, Image, Segment, Table } from "semantic-ui-react";
import "./style.css";
import { SouthAfricanRand } from "../../utils/helpers";

const ProductCreateEditForm = ({
	product,
	goBack
}) => {

	const sanitizedData = () => ({
		__html: DOMPurify.sanitize(product.html)
	});

	return (
		<>
			<Form size="small">
				<Grid container>
					<Grid.Row>
						<Grid.Column width={16} verticalAlign="middle">
							<Button floated="left"
							        size="tiny"
							        type="button"
							        className="blue-basic-button borderless"
							        onClick={goBack}>
								<Icon name="arrow left"/>
								Back to products
							</Button>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row centered>
						<Grid.Column width={8}>
							<Segment padded>
								<Grid container>
									<Grid.Row>
										<Grid.Column width={16}>
											<Header as="h6">
												<Icon name="info circle"/>
												Information
											</Header>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row centered>
										<Grid.Column width={16}>
											<Table basic="very" collapsing className="borderless-table">
												<Table.Body>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Name</strong>
														</Table.Cell>
														<Table.Cell className="borderless">{product.name}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Sku</strong>
														</Table.Cell>
														<Table.Cell className="borderless">{product.sku}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Category</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.productCategory?.replaceAll("_", " ")}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Type</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.productType?.replaceAll("_", " ")}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Description</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.description}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Vendor</strong>
														</Table.Cell>
														<Table.Cell className="borderless">{product.vendor}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Quality</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.quality}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Warranty</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.warranty}</Table.Cell>
													</Table.Row>
													<Table.Row>
														<Table.Cell className="borderless" textAlign="right">
															<strong>Availability</strong>
														</Table.Cell>
														<Table.Cell
															className="borderless">{product.availability}</Table.Cell>
													</Table.Row>
												</Table.Body>
											</Table>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Segment>
						</Grid.Column>
						<Grid.Column width={8}>
							{!!product?.prices &&
								<Segment padded>
									<Grid container>
										<Grid.Row>
											<Grid.Column width={16}>
												<Header as="h6">
													<Icon name="warehouse"/>
													Stock Info
												</Header>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row centered>
											<Grid.Column width={16}>
												<Table basic="very" collapsing className="borderless-table">
													<Table.Body>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Quantity On Hand</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{product.prices.quantityOnHand}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Latest Cost</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.latestCost)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 1</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail1)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 2</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail2)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 3</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail3)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 4</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail4)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 5</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail5)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 6</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail6)}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell className="borderless" textAlign="right">
																<strong>Retail 10</strong>
															</Table.Cell>
															<Table.Cell
																className="borderless">{SouthAfricanRand.format(product.prices.retail10)}</Table.Cell>
														</Table.Row>
													</Table.Body>
												</Table>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							}
						</Grid.Column>
					</Grid.Row>
					{!!product?.image &&
						<Grid.Row>
							<Grid.Column width={16}>
								<Segment padded>
									<Grid container>
										<Grid.Row>
											<Grid.Column width={16}>
												<Header as="h6">
													<Icon name="picture"/>
													Media
												</Header>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row centered>
											<Grid.Column width={16} textAlign="center" verticalAlign="middle">
												<Image centered size="medium" src={product.image} alt={product.name}
												       fluid/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					}
					{!!product?.html &&
						<Grid.Row>
							<Grid.Column width={16}>
								<Segment padded>
									<Grid container>
										<Grid.Row>
											<Grid.Column width={16}>
												<Header as="h6">
													<Icon name="code"/>
													Body HTML
												</Header>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row>
											<Grid.Column width={16}>
												<div dangerouslySetInnerHTML={sanitizedData()}/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							</Grid.Column>
						</Grid.Row>
					}
				</Grid>
			</Form>
		</>
	);

};

export default ProductCreateEditForm;